import React from "react"
import Layout from "../components/layout"
import PrivacyPolicies from "../components/politicas-de-privacidad/PrivacyPolicies"
//data
import pageData from "../data/pageData"

const PoliticasPrivacidad = ({ location }) => (
  <Layout location={location} >
    <PrivacyPolicies title={pageData.privacyPolicies.title} privacyData={pageData.privacyPolicies.about} items={pageData.privacyPolicies.items} />
  </Layout>
)

export default PoliticasPrivacidad